var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('p',{staticClass:"clav-content-title-1"},[_vm._v("Autos de Eliminação")]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Quando a documentação atinge o término do seu prazo de conservação administrativa e o seu destino final é a eliminação, a ação de eliminar deve ser concretizada seguindo as boas práticas. Na CLAV é possível criar os autos de eliminação ao abrigo de tabela de seleção inserida em Portaria de gestão de documentos ou Relatório de avaliação de documentação acumulada, e submetê-los à DGLAB para verificação de conformidade. ")]),_c('p',{staticClass:"content-title px-8 py-2"},[_vm._v(" Para aceder a autos de eliminação, selecione a opção "),_c('b',[_vm._v("CONSULTAR")]),_vm._v(". ")]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Para criar autos de eliminação, selecione a opção "),_c('b',[_vm._v("CRIAR. Deve estar registado na CLAV para aceder a esta funcionalidade.")])]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Para importar autos de eliminação, selecione a opção "),_c('b',[_vm._v("IMPORTAR. Deve estar registado na CLAV para aceder a esta funcionalidade.")])]),_c('p',{staticClass:"clav-content-text px-8 pb-4"},[_c('b',[_vm._v("Consulte as instruções em "),_c('a',{on:{"click":function($event){return _vm.$router.push('/forminstr')}}},[_vm._v("Formulários e instruções")]),_vm._v(".")])]),_c('v-container',{staticStyle:{"text-align":"center"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
            'px-8': _vm.$vuetify.breakpoint.lgAndUp,
            'px-2': _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/autosEliminacao/consultar')}}},[_c('unicon',{attrs:{"name":"consultar-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.697","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Consultar")])],1)],1),(
          this.$userLevel() > 0 &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
            'px-8': _vm.$vuetify.breakpoint.lgAndUp,
            'px-2': _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/autosEliminacao/criar')}}},[_c('unicon',{attrs:{"name":"criar-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.721","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Criar")])],1)],1):_vm._e(),(
          this.$userLevel() > 0 &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
            'px-8': _vm.$vuetify.breakpoint.lgAndUp,
            'px-2': _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/autosEliminacao/importar')}}},[_c('unicon',{attrs:{"name":"importar-icon","width":"20","height":"20","viewBox":"0 0 20.71 18.121","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Importar")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }